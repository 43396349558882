.growth-trend-card-body {
    padding: 15px;
    border-radius: 10px;
    background-color: #FFFFFF;
}

.growth-trend-card-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #40404D;
    display: flex;
}

.growth-trend-card-value {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    /* identical to box height, or 144% */


    /* Gray/Gray */

    color: #101020;
}

.growth-trend-card-time {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */


    /* Gray/Gray-300 */

    color: #707079;
}