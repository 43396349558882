.arkreen-main {
    padding:20px 20px
}

.arkreen-main-title {
    font-size:24px;
    line-height:32px;
    padding-block-end:10px;
    margin-top: -10px;
}

.ant-tag {
    margin-inline-end: 0 !important;
}

.photo-wall {
    display: flex;
    gap: 16px;
    flex: 1;
    width: 100%;
}

.photo-item {
    flex: 1;
    padding-block: 8px 12px;
    position: relative;
}

.photo-item-img {
    background-color: #B7B7BC66;
    width: 100%;
    min-width: 150px;
    min-height: 120px;
}

.photo-item-address {
    color: #101020;
    padding: 2px;
    font-size: 14px;
    cursor: pointer;
    height: 20px;
    line-height: 18px;
    font-weight: bold;
}

.photo-item-photo {
    position: relative;
    top: 0;
    left: 0;
    border-radius: 14px;
    overflow: hidden;
}

.photo-item-country-icon {
    display: flex;
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.photo-item-photo:hover .photo-item-photo-hover {
    display: flex;
    cursor: pointer;
    color: #FFFFFF;
    gap: 6px;
    z-index: 9;
}

.photo-item-photo .photo-item-photo-hover {
    display: none;
    position: absolute;
    background-color: #00000044;
    backdrop-filter: saturate(150%) contrast(80%) blur(1px);
    top: 0;
    width: calc(100% - 0px);
    height: calc(100% - 4px);
    text-align: center;
    justify-content: center;
    align-items: center;
}

.photo-item-capacity {
    flex: 1;
    color: #101020;
    padding: 2px;
    font-size: 14px;
    height: 20px;
    line-height: 18px;
    text-align: right;
}

.photo-wall-title {
    font-size: 24px;
    line-height: 32px;
    text-align: left;
    font-weight: bold;
}

.photo-wall-description {
    font-size: 14px;
    line-height: 26px;
    text-align: left;
    color: #707079;
}

.photo-wall-banner {
    background: linear-gradient(90deg, rgba(0, 145, 58, 0.1) 0%, rgba(0, 145, 58, 0.05) 50%, rgba(0, 145, 58, 0.1) 100%);
    padding: 6px 0 6px 24px;
    border-radius: 14px;
}


@media screen and (max-width: 576px) {
    .arkreen-404 {
        text-align: center;
        gap: 4px;
        padding: 16px 16px 16px 16px;
        margin-top: 20%;
    }
    .arkreen-404-info{
        font-weight: 400;
        font-size: 14px;
        text-align:center;
        padding: 8px;
    }
    .arkreen-select-group {
        display:flex;
    }
    .arkreen-select-item {
        width: 50%;
        float: left;
    }
    .arkreen-select-padding-left {
        padding-left: 6px;
    }
    .arkreen-select-padding-right {
        padding-right: 6px;
    }
}

@media screen and (min-width: 576px) {
    .arkreen-main-content {
        background-color:#FFFFFF;
        padding:16px 16px 16px 16px;
    }

    .arkreen-404 {
        width:100%;
        text-align: center;
        padding-top: 15%;
    }

    .arkreen-404-info{
        font-weight: 400;
        font-size: 14px;
        text-align:center;
        padding: 8px;
    }
    .arkreen-select-group {
        display:flex;
        padding-bottom: 6px;
    }
    .arkreen-select-item {
        float: left;
        padding-inline-end: 16px;
    }
}

.web3string {
    font-family: 'Roboto Mono', monospace;
}

.arkreen-404-title {
    width: 100%;
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    color: #101020;
    padding: 8px;
}

.arkreen-404-btn {
    width: 100%;
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #00913A;
    padding: 8px;
}

.arkreen-404-back {
    color: #00913A;
    border: 1px solid #00913A;
    border-radius: 16px;
    align-items: center;
    padding: 5px 16px;
}

.arkreen-404-back:hover {
    color: #00913A !important;
    border: 1px solid #00913A !important;
    background-color: #E6F4EB;
}

.ant-layout .ant-layout-sider {
    transition:none !important;
}
