.table-border {
    border: 1px solid #F6F6F9;
    border-radius: 8px;
}

.ant-table-thead .ant-table-cell {
    color: #707079 !important;
}

.ant-table-wrapper .ant-table-thead >tr>th, .ant-table-wrapper .ant-table-thead >tr>td {
    background: #FFFFFF !important;
    font-weight: 500 !important;
    font-size: 14px !important;
}

.ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before, .ant-table-wrapper .ant-table-thead >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none !important;
}

@media screen and (max-width: 576px) {
    .arkreen-table-pc {
        display: none;
    }
    .arkreen-table-m {
        display: block;
    }
}

@media screen and (min-width: 576px) {
    .arkreen-table-pc {
        display: block;
    }
    .arkreen-table-m {
        display: none;
    }
}

.arkreen-m-table {
    padding: 12px 16px;
    background: #FFFFFF;
    border-radius: 8px;
    margin-bottom: 16px;
}