.detail-info-box {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #101020;
    margin-top: 8px;
    margin-bottom: 8px;
}

.copy-btn {
    margin-inline: 5px;
    color: #40404D;
}

.copy-btn:hover {
    cursor: pointer;
    color: #00913A;
}

.detail-header {
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 12px 16px;
}

.detail-header-title {
    font-size:16px;
    line-height:32px;
    padding: 16px 10px 0 0
}

.detail-header-info-box {
    display: flex;
    flex-wrap: wrap;
}

.detail-header-info-box-left {
    padding-block-start: 20px;
    flex:1;
    min-width: 500px;
}

.detail-header-info-box-right {
    flex: 1;
    padding-left: 40px;
    padding-block-start: 20px;
}

.miner-images {
    border-radius:6px;
    width: 100%;
    max-width: 700px;
}

.detail-miner-images {
    background-color: #FFFFFF;
    padding: 16px;
    max-height: 100%;
    overflow: scroll;
    border-radius: 8px;
    margin-top: 20px;
    width: 100%;
}

@media screen and (max-width: 576px) {
    
    .detail-header-info {
        height: auto;
    }

    .detail-header-info-title {
        width: 100%;
        color: #40404D;
        font-size: 16px;
        font-weight: 400;
        vertical-align: middle;
        padding-block: 6px;
    }
    
    .detail-header-info-value {
        padding-block: 6px;
        width: 100%;
        font-weight: 700;
        font-size: 16px;
        text-align: left;
        color: #101020;
        padding-left: 28px;
        word-wrap: break-word;
        word-break: normal;
    }

    .detail-table-box {
        max-height:100%;
        overflow:scroll;
        border-radius: 8px;
        margin-top: 20px;
        width: 100%;
    }

    .detail-table-box-tx {
        background-color:#FFFFFF;
        padding:16px;
        max-height:100%;
        border-radius: 8px;
        margin-top: 20px;
        width: 100%;
    }

    .ant-tabs-tab {
       width: 50%;
    }

    .ant-tabs-tab-btn {
        width: 100% !important;
        text-align: center !important;
    }
}

.ant-tabs-nav-list {
    width: 100% !important;
}

@media screen and (min-width: 576px) {
    
    .detail-table-box {
        background-color:#FFFFFF;
        padding:16px;
        max-height:100%;
        overflow:scroll;
        border-radius: 8px;
        margin-top: 20px;
        width: 100%;
    }

    .detail-no-tab {
        background-color:#FFFFFF
    }

    .detail-header-info {
        height: 32px;
        line-height: 32px;
        width: 25%;
        min-width: 500px;
    }
    
    .detail-header-info-title {
        float: left;
        color: #40404D;
        font-size: 16px;
        font-weight: 400;
        vertical-align: middle;
    }
    
    .detail-header-info-value {
        float: right;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: right;
        color: #101020;
        margin-top: 4px;
    }
}

.detail-header-block-height {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #40404D;
    margin-left: 10px;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #00913A !important;
    text-shadow: 0 0 0.25px currentcolor;
}

.ant-tabs .ant-tabs-tab {
    font-size: 16px !important; 
    line-height:24px !important; 
    font-weight:300 !important; 
    color:#40404D !important; 
    padding: 8px 24px !important;
}

.ant-tabs .ant-tabs-tab:hover {
    color: #00913A !important;
}

.ant-tabs .ant-tabs-ink-bar {
    background: #00913A !important;
    height: 4px !important;
}

.ant-tabs >.ant-tabs-nav, .ant-tabs >div>.ant-tabs-nav {
    position: revert !important;
}